import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
  clearError,
  myOrdersDetails,
  updateOrder,
} from "../../actions/orderAction";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstant";
import DashboardSidebar from "../Dashboard/DashboardSidebar/DashboardSidebar";
import MetaData from "../MetaData/MetaData";

const OrderStatus = () => {
  const alert = useAlert();
  const history = useHistory();
  const match = useRouteMatch();
  const { order, error, loading } = useSelector((state) => state.orderDetails);
  const { error: updateError, isUpdated } = useSelector((state) => state.order);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  const updateStatusOfOrder = (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set("status", status);
    dispatch(updateOrder(match.params.id, myForm));
  };
  ///
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearError());
    }
    if (updateError) {
      alert.error(updateError);
      dispatch(clearError());
    }
    if (isUpdated) {
      alert.success("order updated successfully");
      dispatch({ type: UPDATE_ORDER_RESET });
    }
    dispatch(myOrdersDetails(match.params.id));
  }, [
    dispatch,
    error,
    updateError,
    alert,
    match.params.id,
    isUpdated,
    history,
  ]);

  return (
    <>
      <MetaData title="ORDER STATUS CHANGE" />
      <DashboardSidebar />

      <div className="pt-4"></div>
      {order?.orderStatus === "Delivered" ? (
        <div className="add-product need-padding-for-uploading-product">
          <div className="pt-4"></div>
          <h1 className="pt-5 text-center">Update Order Completed</h1>
          <Link to="/admin/orders">
            <button>BACK ORDER LIST</button>
          </Link>
        </div>
      ) : (
        <div className="add-product need-padding-for-uploading-product">
          <form encType="multipart/form-data" onSubmit={updateStatusOfOrder}>
            {" "}
            <h1 className="pt-5">Update Order</h1>
            <div className="admin-make">
              <select
                onChange={(e) => setStatus(e.target.value)}
                className="mt-3"
              >
                <option value="">Select status</option>
             
                  <option value="Shipped">Shipped</option>
                 <option value="Delivered">Delivered</option>
             
              </select>
            </div>
            <button
              type="submit"
              disabled={loading ? true : false || status === "" ? true : false}
            >
              {" "}
              {order?.order?.orderStatus === "Shipped"
                ? "ORDER DELIVERED"
                : "UPDATE STATUS"}
            </button>
          </form>

          <div style={{marginTop:"3rem"}} className="shipping-information">
            <div className="left-side-information">
              <label> name *</label> <br />
              <input type="text" value={order?.user?.name} readOnly />
              <label> Number *</label> <br />
              <input
                type="text"
                value={order?.order?.shippingInfo?.phoneNo}
                readOnly
              />
              <label> country *</label> <br />
              <input
                type="text"
                value={order?.order?.shippingInfo?.country}
                readOnly
              />
            </div>
            <div className="left-side-information">
              <label> Email *</label> <br />
              <input type="text" value={order?.user?.email} readOnly />
              <label> city *</label> <br />
              <input type="text" value={order?.order?.shippingInfo?.city} readOnly />
              <label> state *</label> <br />
              <input type="text" value={order?.order?.shippingInfo?.state} readOnly />
            </div>
          </div>

          <h1 className="shipping text-center mb-5">Payment Information</h1>

<div className="shipping-information">
  <div className="left-side-information">
    <label> name *</label> <br />
    <input type="text" value={order?.user?.name} readOnly />
    <label> Payment ID *</label> <br />
    <input type="text" value={order?.order?.paymentInfo?.id} readOnly />
    <label> Amount *</label> <br />
    <input type="text" value={`$${order?.order?.totalPrice}`} readOnly />
  </div>
  <div className="left-side-information">
    <label>Status *</label> <br />
    <input type="text" value={order?.order?.paymentInfo?.status} readOnly />
    <label> isPad *</label> <br />
    <input
      type="text"
      value={
        order?.order?.paymentInfo &&
        order?.order?.paymentInfo.status === "succeeded"
          ? "PAID"
          : "NOT PAID YET"
      }
      readOnly
    />
  </div>
</div>
<h1 className="shipping text-center mb-5">Order Information</h1>
<div className="need-custom-padding">
  <div className="product-sending-parent-section">
    <div className="customization">
      {order?.order?.orderItems?.map((data) => (
        <>
          <div className="user-cart" key={data.product}>
            <img src={data.image} alt="" />
            <h6 className="font-family">{data.name}</h6>

            <div className="quantity-item">
              <p className="font-family">{`${data.quantity} X ${data.price}`}</p>
            </div>

            <p className="font-family">
              RS {data.price * data.quantity}
            </p>
          </div>
        </>
      ))}

    </div>
  </div>

  {order?.order?.paymentProof && ( <img src={order?.order?.paymentProof} width={400}/>)}
</div>

{/* )} */}
        </div>
      )}
    </>
  );
};

export default OrderStatus;
