/// product create
export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";
///get review all
export const ALL_WEB_REVIEW_REQUEST = "ALL_REVIEW_REQUEST";
export const ALL_WEB_REVIEW_SUCCESS = "ALL_WEB_REVIEW_SUCCESS";
export const ALL_WEB_REVIEW_FAIL = "ALL_WEB_REVIEW_FAIL";
///
export const DELETE_REQUEST_WEB = "DELETE_REQUEST_WEB";
export const DELETE_SUCCESS_WEB = "DELETE_SUCCESS_WEB";
export const DELETE_FAIL_WEB = "DELETE_FAIL_WEB";
export const DELETE_RESET_WEB = "DELETE_RESET_WEB";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
