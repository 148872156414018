/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearError, getAllSubscribe } from "../../actions/subscribeAction";
const Footer = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, subscribe } = useSelector(
    (state) => state.getSubscribe
  );
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearError());
    }
    dispatch(getAllSubscribe());
  }, [dispatch, error, alert]);
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="about-site">
          <h4>About The Store</h4>
          <p>
          We are dedicated to offering a unique blend of tradition and modernity through our carefully curated collections. From exquisite embroidered pieces to chic seasonal outfits, every item is crafted with attention to detail and a commitment to quality. We strive to bring you timeless designs that reflect elegance and sophistication, ensuring you look and feel your best for every occasion. 
          Whether it’s winter, autumn, or spring, explore our range of luxurious fabrics and intricate craftsmanship, tailored to meet your fashion needs.
          </p>
        </div>

        <div className="customer-service-n-social-icon">
          <h4>Customer Support</h4>
          <ul>
            <li>
              <Link to="/services">Customer Service</Link>
            </li>
            <li>
              <Link to="/products">product</Link>
            </li>
            <li>
              <Link to="/account">Account</Link>
            </li>
            <li>
              <Link to="/login">Email Signup</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="customer-service-n-social-icon">
          <div className="about-site">
            <h4>Address</h4>
            <ul>
              <li>
                <Link to="/">
                  <EditLocationOutlinedIcon />
                  Shop # 14,  Saddar Cantt: Hyderabad
                </Link>
              </li>
              <li>
                <a
                  href="https://goo.gl/maps/65dDBsL52BM2CpEE9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapOutlinedIcon />
                  map
                </a>
                </li>
                
                <li>
                <a
                  href="https://it-z-riaz-site.web.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PublicIcon /> website
                </a>
              </li>
            </ul>
            <div className="social-div">
              <p>
                Follow me on <ArrowRightAltOutlinedIcon />
              </p>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/share/QZh2WmiCHrDgxm8Q/?mibextid=LQQJ4d"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/newadamjee?igsh=MW40dHVuaWdsbm5obw=="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  <InstagramIcon />
                    <PublicIcon />
                  </a>
                </li>
              </ul>
            </div>
            <div className="social-div">
              <p>
                Subscribe <ArrowRightAltOutlinedIcon />
              </p>
              <ul>
                <li>
                  <a>There are {subscribe?.length} people subscribed.</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center footer-last-caption">
        <p>
          @all right reserved{" "}
          
            NEWADAMJEEFABRICS
        </p>
      </div>
    </div>
  );
};

export default Footer;
