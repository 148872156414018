/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Navbar from "../Navbar/Navbar";
import MetaData from "../MetaData/MetaData";
import CheckoutStep from "../CheckoutStep/CheckoutStep";
import axios from "axios";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventIcon from "@material-ui/icons/Event";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { clearError, createOrder } from "../../actions/orderAction";
import "./Payment.css";
import Footer from "../Footer/Footer";
import { useHistory } from "react-router-dom";

const Payment = () => {
  let history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [screenshot, setScreenshot] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));

  const dispatch = useDispatch();
  const alert = useAlert();
  const stripe = useStripe();
  const elements = useElements();

  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);

  const paymentData = {
    amount: Math.round(orderInfo.totalPrice * 100),
  };

  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: orderInfo.subtotal,
    taxPrice: orderInfo.tax,
    shippingPrice: orderInfo.shippingCharges,
    totalPrice: orderInfo.totalPrice,
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    if (paymentMethod === "card") {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post(
          "/api/v1/payment/process",
          paymentData,
          config
        );

        const client_secret = data.client_secret;

        if (!stripe || !elements) return;

        const result = await stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: user.name,
              email: user.email,
              address: {
                line1: shippingInfo.address,
                city: shippingInfo.city,
                state: shippingInfo.state,
                postal_code: shippingInfo.pinCode,
                country: shippingInfo.country,
              },
            },
          },
        });

        if (result.error) {
          setIsButtonDisabled(false);
          alert.error(result.error.message);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            order.paymentInfo = {
              id: result.paymentIntent.id,
              status: result.paymentIntent.status,
            };
            dispatch(createOrder(order));
            history.push("/success");
          } else {
            alert.error("There's some issue while processing payment");
          }
        }
      } catch (error) {
        setIsButtonDisabled(false);
        console.log(error);
      }
    } else if (paymentMethod === "cod") {
      order.paymentInfo = {
        id: "COD",
        status: "pending",
      };
      dispatch(createOrder(order));
      history.push("/success");
    } else if (paymentMethod === "proof") {


      if (!screenshot) {
        alert.error("Please upload a screenshot for payment proof");
        setIsButtonDisabled(false);
        return;
      }
  
      // Convert screenshot to base64 string
      const reader = new FileReader();
      reader.readAsDataURL(screenshot);
      reader.onloadend = () => {
        const base64String = reader.result;
  
        // Include base64 string in order.paymentProof
        order.paymentProof = base64String,
      
  
        order.paymentInfo = {
          id: "Payment Proof",
          status: "pending",
        };
  
        dispatch(createOrder(order));
        history.push("/success");
      };
      reader.onerror = () => {
        alert.error("Failed to upload the screenshot");
        setIsButtonDisabled(false);
      };


    


    }
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, alert]);

  return (
    <>
      <Navbar />
      <MetaData title="Payment Order" />
      <div className="shop-container-shipping-caption" data-aos="fade-up">
        <p>Home / Payment Order</p>
        <h3>Payment Order</h3>
      </div>
      <div className="mb-5 pb-5" data-aos="fade-up">
        <CheckoutStep activeStep={2} />
      </div>
      <div className="payment_success_item" data-aos="fade-right">
        <h1>Payment Order</h1>
      </div>

      <div className="paymentContainer" data-aos="fade-right">
        <form className="paymentForm" onSubmit={submitHandler}>
          <div className="paymentMethod" style={{ display: "flex", flexDirection: "column" }}>
            <label>
              <input
                type="radio"
                value="card"
                checked={paymentMethod === "card"}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              Pay by Card
            </label>
            <label>
              <input
                type="radio"
                value="cod"
                checked={paymentMethod === "cod"}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              Cash on Delivery
            </label>
            <label>
              <input
                type="radio"
                value="proof"
                checked={paymentMethod === "proof"}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              Upload Payment Proof
            </label>
          </div>

          {paymentMethod === "card" && (
            <>
              <div>
                <CreditCardIcon />
                <CardNumberElement className="paymentInput" />
              </div>
              <div>
                <EventIcon />
                <CardExpiryElement className="paymentInput" />
              </div>
              <div>
                <VpnKeyIcon />
                <CardCvcElement className="paymentInput" />
              </div>
            </>
          )}

          {paymentMethod === "proof" && (
            <>
              <div style={{ display: "block", width: "100%" }} className="bank-details">
                <div>
                  <h4>Bank Account Details:</h4>
                  <p>Account Title: DAWOOD</p>
                  <p>Account Number: 16110110608974</p>
                  <p>Bank: Mezan Bank</p>
                </div>
                <div>
                  <h4>JazzCash Account:</h4>
                  <p>JazzCash Account: 03197910708</p>
                  <p>Account Holder: Daud Daud</p>
                </div>
              </div>

              <div>
                <label>Upload Screenshot</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setScreenshot(e.target.files[0])}
                />
              </div>
            </>
          )}

          <button
            type="submit"
            className="paymentFormBtn"
            disabled={isButtonDisabled}
          >
            {paymentMethod === "card"
              ? `Pay - RS ${orderInfo && orderInfo.totalPrice}`
              : paymentMethod === "cod"
              ? "Confirm COD Order"
              : "Submit Payment Proof"}
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Payment;
