import image1 from "../image/categories/saeed.jpg"
import image2 from "../image/categories/abc.jpg" 
import image3 from "../image/categories/saya1.jpg"
import image4 from "../image/categories/tawakkal.jpg"
import image0 from "../image/categories/sale.jpg"

export const categoriesData0 = [
  {
    name: "SALE",
    description: `It’s sale time! Unbeatable prices on the best styles. Shop before it’s too late!.`,
    category: "sale",
    image: image0,
    fade: "",
    fadeTwo: "",
  },
];
export const categoriesData1 = [
  {
    name: "Bin Saeed",
    description: `Bin Saeed has set out to achieve success by crafting true relationships within the industry, 
    maintaining high quality of our products, and solid partnerships with our clients. Over the years, 
    Bin Saeed has transformed into a well-established brand by maintaining and staying true to our reality.`,
    category: "binsaeed",
    image: image1,
    fade: "",
    fadeTwo: "",
  },
];
export const categoriesData2 = [
  {
    name: "Tawakkal",
    description: `This long-established family-run textile business specializes in creating unique and fashionable designs for women's clothing. 
    Their extensive range of high-quality fabrics includes popular choices such as lawn, chiffon, khadar, linen, cotton, chickenkari, banarsi, and Mesuri.`,
    category: "tawakal",
    image: image4,
    fade: "",
    fadeTwo: "",
  },
];
export const categoriesData3 = [
  {
    name: "ABC lahore",
    description: `Buy fabric for your next sewing project, with a wide variety of cotton, crepe, chiffon, shirt and much more, all sold by the meter.`,
    category: "abclahore",
    image: image2,
    fade: "",
    fadeTwo: "",
  },
];
export const categoriesData4 = [
  {
    name: "SAYA",
    description: `A wide range of unstitched and ready-to-wear garments for men and women. 
    Choose from a variety of fabrics, designs, and colors to find the perfect outfit for any occasion.`,
    category: "saya",
    image: image3,
    fade: "",
    fadeTwo: "",
  },
];